/**
 * Dropzone styles
 */

#upload-image-dropzone-wrapper {
	background-color: #eee;
	border-radius: 4px;
	border: 2px dashed #ccc;

	&.drag-accept {
		background-color: #d4edda;
		border-color: #c3e6cb;
		color: #155724;
	}

	&.drag-reject {
		background-color: #f8d7da;
		border-color: #f5c6cb;
		color: #721c24;
	}

	p {
		margin-bottom: 0;
	}
}
